@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: PepeFont;
  src: url(./assets/fonts/PepeSemi.woff2);
}
@font-face {
  font-family: PepeRegularFont;
  src: url(./assets/fonts/PepeRegular.woff2);
}
@font-face {
  font-family: PepeBoldFont;
  src: url(./assets/fonts/PepeBold.woff2);
}
.section-spepe {
  --fill-layer-image-opacity: 0.15;
}

.text-reverse {
  transform: rotate(180deg);
  direction: rtl;
}

.rotate {
  transform: rotate(180deg) !important;
}

p,
a,
h1,
h2,
h3,
h4,
h5,
span,
ul,
button {
  display: block;
  transform: rotate(180deg) !important;
  direction: rtl;
}
